@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
.logo {
  margin-right: 1rem;
  width: 8rem;
  img {
    width: 100%;
  }
}

.apg-breadcrumb-link {
  cursor: pointer;
  &:hover {
    color: #007bff;
  }
}

.ant-modal-close-x {
  display: flex !important;
  justify-content: center;
}

.anticon {
  align-items: center;
  display: flex !important;
  justify-content: center;

  &.anticon-vertical-align-top {
    height: 2.5rem;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #000000 #E6E6E6;
}
*::-webkit-scrollbar{
  width: 0.375rem;
  height: 0.375rem;
}
*::-webkit-scrollbar-thumb{
  background: #000000;
}
*::-webkit-scrollbar-thumb:hover{
  background: #6E7070;
}
*::-webkit-scrollbar-track{
  background: #E6E6E6;
  box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --carousel-slider-max-width: 100vw;
  --carousel-slider-width: 432px;
  --carousel-slider-height: 600px;
  --carousel-slider-navigation-color: #000;
  --carousel-slider-pagination-color: #000;
}

@media only screen and (max-width: 520px) {
  :root {
    --carousel-slider-width: 70vw;
    --carousel-slider-height: 70vh;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#carousel-swiper .swiper-slide {
  width: 432px;
  width: var(--carousel-slider-width);
  height: 600px;
  height: var(--carousel-slider-height);
  position: relative;
}
